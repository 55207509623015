import lottie from 'lottie-web';
import crashAnim from './data';

class LottieAnimation {
    animator = null;

    constructor() {
        this.init();
    }

    init = () => {
        this.animator = lottie.loadAnimation({
            container: document.querySelector('.launch-crash-anim'), // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: crashAnim,
        });

        document.addEventListener('aos:in', this.onElementIn);
    }

    onElementIn = ({detail}) => {
        if (detail?.classList?.contains('launch-crash-anim')) {
            setTimeout(() => {
                this.animator?.play();
            }, 700);
        }
    }
}

export default LottieAnimation;
