import Splide from "@splidejs/splide";

// import '@splidejs/splide/dist/css/splide.min.css';

class HolywallyApp {
    header = null;
    toggleBtn = null;

    homeUsecasesSlider = null;

    constructor() {
        this.init();
        this.initMobileToggle();
        this.initUsecases();
        this.initForms();
        this.initPartnerForm();
    }

    onMobileNavToggle = () => {
        const mobileNav = document.querySelector(".mobile-navbar");
        if (!mobileNav) return;

        mobileNav.classList.toggle("active");
        this.toggleBtn.classList.toggle("active");

        document.querySelector("body").classList.toggle("lock");
    };

    initMobileToggle = () => {
        this.toggleBtn = document.querySelector(".menu-toggle");
        if (this.toggleBtn) {
            this.toggleBtn.addEventListener("click", this.onMobileNavToggle);
        }
    };

    toggleRegisterForm = (e) => {
        e.preventDefault();
        document.querySelector("#register-now-form").classList.toggle("open");
    };

    initForms = () => {
        document
            .querySelectorAll('[data-toggle="register-now"]')
            .forEach((item) => {
                item.addEventListener("click", this.toggleRegisterForm);
            });

        document
            .querySelector("#demo_request_market")
            .addEventListener("change", (e) => {
                const messageElement =
                    document.querySelector("#market-message");
                if (e.target.value !== "Singapore") {
                    messageElement.style.display = "block";
                } else {
                    messageElement.style.display = "none";
                }
            });
    };

    toggleRegisterPartnerForm = (e) => {
        e.preventDefault();
        document
            .querySelector("#register-now-partner-form")
            .classList.toggle("open");
    };

    initPartnerForm = () => {
        document
            .querySelectorAll('[data-toggle="register-partner"]')
            .forEach((item) => {
                item.addEventListener("click", this.toggleRegisterPartnerForm);
            });
    };

    initUsecases = () => {
        const usecasesWrapper = document.querySelectorAll(
            '[data-toggle="usecase"]'
        );
        usecasesWrapper.forEach((item) => {
            item.addEventListener("click", () => {
                document
                    .querySelectorAll(".case-layout.active")
                    .forEach((item) => item.classList.remove("active"));
                document
                    .querySelector(`#${item.dataset?.target}`)
                    ?.classList.add("active");
                document
                    .querySelector("#usecases-menu")
                    ?.classList.remove("show");
            });
        });
        document
            .querySelector('[data-toggle="usecases-menu"]')
            ?.addEventListener("click", () => {
                document
                    .querySelector("#usecases-menu")
                    .classList.toggle("show");
            });
    };

    onScroll = () => {
        const top = window.pageYOffset || document.documentElement.scrollTop;
        if (top > 50) {
            this.header.classList.add("active");
        } else {
            this.header.classList.remove("active");
        }
    };

    initImageRotation = () => {
        const images = [
            document.getElementById("rotating-image1"),
            document.getElementById("rotating-image2"),
        ];
        let currentIndex = 0;

        function rotateImages() {
            images[currentIndex].style.opacity = 0;
            currentIndex = (currentIndex + 1) % images.length;
            images[currentIndex].style.opacity = 1;
        }

        setInterval(rotateImages, 6000);
    };

    initSliders = () => {
        // use-cases
        if (document.querySelector("#use-cases-cases")) {
            const useCasesSplide = new Splide("#use-cases-cases", {
                pagination: false,
                perPage: 1,
            }).mount();
            document
                .querySelector(".cases-root .splide__arrow--prev")
                .classList.add("hide");
            useCasesSplide.on("moved", (index) => {
                document
                    .querySelector(".cases-root .splide__arrow--next")
                    .classList.remove("hide");
                document
                    .querySelector(".cases-root .splide__arrow--prev")
                    .classList.remove("hide");
                if (index === 0) {
                    document
                        .querySelector(".cases-root .splide__arrow--prev")
                        .classList.add("hide");
                }
                if (index === 4) {
                    document
                        .querySelector(".cases-root .splide__arrow--next")
                        .classList.add("hide");
                }
            });
            document
                .querySelectorAll('[data-action="splide-to"]')
                .forEach((item) => {
                    item.addEventListener("click", () => {
                        useCasesSplide?.go(item.dataset.target);
                    });
                });
        }
        // use-cases

        if (document.querySelector("#home-use-cases")) {
            this.homeUsecasesSlider = new Splide("#home-use-cases", {
                pagination: false,
                perPage: 4,
                classes: {
                    arrows: "splide__arrows slider-arrows",
                    arrow: "splide__arrow slider-arrow",
                    prev: "splide__arrow--prev home-slider-arrow-left",
                    next: "splide__arrow--next home-slider-arrow-right",
                },
                padding: {
                    left: (window.innerWidth - 1440) / 2 + 155,
                    right: 0,
                },
                breakpoints: {
                    700: {
                        perPage: 1,
                        padding: { left: "2rem" },
                    },
                    991: {
                        perPage: 2,
                        padding: { left: "2rem" },
                    },
                    1150: {
                        perPage: 2,
                        padding: { left: 155 },
                    },
                    1515: {
                        padding: {
                            left: 155,
                        },
                        perPage: 3,
                    },
                },
            });
            this.homeUsecasesSlider.mount();
        }
        if (document.querySelector("#home-how-it-works")) {
            const howItWorksSplide = new Splide("#home-how-it-works", {
                pagination: false,
            }).mount();
            document
                .querySelector(".how-it-works .splide__arrow--prev")
                .classList.add("hide");
            howItWorksSplide.on("moved", (index) => {
                document
                    .querySelector(".how-it-works .splide__arrow--next")
                    .classList.remove("hide");
                document
                    .querySelector(".how-it-works .splide__arrow--prev")
                    .classList.remove("hide");
                if (index === 0) {
                    document
                        .querySelector(".how-it-works .splide__arrow--prev")
                        .classList.add("hide");
                }
                if (index === 2) {
                    document
                        .querySelector(".how-it-works .splide__arrow--next")
                        .classList.add("hide");
                }
            });
            document
                .querySelectorAll('[data-action="splide-to"]')
                .forEach((item) => {
                    item.addEventListener("click", () => {
                        howItWorksSplide?.go(item.dataset.target);
                    });
                });
        }

        window.addEventListener("resize", () => {
            if (this.homeUsecasesSlider) {
                this.homeUsecasesSlider.refresh();
            }
        });
    };

    init = () => {
        this.header = document.querySelector("header");
        if (this.header) {
            document.addEventListener("scroll", this.onScroll);
        }
        document.addEventListener("turbolinks:click", () => {
            const el = document.querySelector(".page-load");
            if (el) {
                el.classList.add("show");
            }
        });
        document.addEventListener("turbolinks:render", () => {
            const el = document.querySelector(".page-load");
            if (el) {
                el.classList.remove("show");
            }
        });

        this.initImageRotation();
        this.initSliders();
    };
}

export default HolywallyApp;
