// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import Turbolinks from 'turbolinks';
import AOS from 'aos';

import HolywallyApp from "../holywally-app";
import LottieAnimation from "../lottie";
import Requests from '../requests';

// import 'aos/dist/aos.css';

Rails.start();
ActiveStorage.start();
Turbolinks.start();

document.addEventListener("turbolinks:load", function(event){
    new HolywallyApp();
    new LottieAnimation();
    new Requests();
    AOS.init({
        once: true
    });
});
